import {
    Button,
    Card,
    Col,
    Statistic,
    DatePicker,
    Input,
    Row,
    Space,
    Table,
    Tooltip,
    Menu,
    Dropdown,
    Switch,
    Skeleton,
    Drawer,
    Modal,
    Select,
    Tag,
    Typography,
    Alert
} from "antd";
import { Link, useNavigation, useLoaderData } from "react-router-dom";
import { detailedVolumeFormatter, exportToExcel, currencyFormatter, volumeFormatter } from "../utils";
import React, {useState, useEffect, useRef, InputRef} from "react";
import {fetchOpenOrderData} from "../../endpoints/fetchOpenOrderData";
import Loading from "../Loading";
import {statusOptions} from './OrderForm';
import {useSearchParams} from 'react-router-dom';
import {DownloadOutlined, InfoCircleOutlined, SlidersOutlined, CaretDownOutlined} from "@ant-design/icons";
import OrderSearch from './OrderSearch';
import dayjs from "dayjs";
import {FilterOutlined} from "@ant-design/icons";
import { useOutletContext } from "react-router-dom";
import { fobDisplayLabels, unitDisplayLabels } from "../Settings/OrderSettings";

const colorPrimary = '#4fad96';

const OpenOrderExplorer = (props) => {
    const {userData, setHasError} = props;
    const {accessToken} = userData;
    const {RangePicker} = DatePicker;

    const [forReviewCount, setForReviewCount, products, setProducts, orderUnit, setOrderUnit] = useOutletContext();

    const [searchParams, setSearchParams] = useSearchParams();
    const [sinceDate, setSinceDate] = useState(dayjs().subtract(6, 'months'));
    const [onlyOpen, setOnlyOpen] = useState(true);
    const [currentFilters, setCurrentFilters] = useState({});
    const [loading, setLoading] = useState(true);
    const [drawerVisible, setDrawerVisible] = useState(false);

    const [columns, setColumns] = useState([]);
    const [filteredTableData, setFilteredData] = useState([]);
    const [selectedRows, setSelectedRows] = useState([]);
    const [showSuccessAlert, setShowSuccessAlert] = useState(null);
    const [showFailedAlert, setShowFailedAlert] = useState(null);
    const [bulkStatus, setBulkStatus] = useState('Invoiced');
    const [bulkActionModal, setBulkActionModal] = useState(false);
    const [statusData, setStatusData] = useState([]);
    const [dataSource, setDataSource] = useState(null);
    const [allProductColumns, setAllProductColumns] = useState([]);
    const abortControllerRef = useRef(null);

    const searchInput = useRef(null);  

    const fetchData = async () => {
        setLoading(true);
        // If there's an ongoing fetch, cancel it
        if (abortControllerRef.current && dataSource !== null) {
            abortControllerRef.current.abort();
        }

        // Create a new AbortController
        abortControllerRef.current = new AbortController();

        let data;
        let wasAborted;
        try {
            data = await fetchOpenOrderData(
                accessToken,
                {onlyOpen: onlyOpen, since: sinceDate},
                { signal: abortControllerRef.current.signal }
            );
        } catch (e) {
            if (e.name === 'AbortError') {
                console.log('Fetch aborted');
                wasAborted = true;
            } else {
                wasAborted = false;
                setHasError(e);
            }
            console.log(e);
        }
        console.log(data)

        if (!data) return;

        setDataSource(data);
        setLoading(false);
    }

    const saveBulk = async () => {
        setBulkActionModal(false);
        
        let r;
        try {
            r = await fetchOpenOrderData(
                accessToken,
                {selectedRows: selectedRows, bulkStatusUpdate: bulkStatus},
            );
        } catch (e) {
            setShowFailedAlert(e);
            return
        }
        if (r.errorMessage) setShowFailedAlert(r.errorMessage)
        else {
            setDataSource({...dataSource, tableData: dataSource.tableData.map((row) => {
                if (r.updated_ids.includes(row.order_uuid)) return {...row, 'status': r.status}
                else return row
            })});

            setBulkStatus('Invoiced')
            setShowSuccessAlert(true);
        }
    }

    useEffect(() => {
        const uniqueProductNames = getUniqueProductNames(filteredTableData);
        setColumns(
            allProductColumns.filter((c) => c.dataIndex != 'product_names' || uniqueProductNames.includes(c.key)).map(column => ({
                ...column,
                filteredValue: currentFilters[column.dataIndex] || null,
            }))
        )

        // Recalculate and update statistics
        const newStatusData = aggregateDataByStatus(filteredTableData);
        setStatusData(newStatusData);

    }, [filteredTableData]);

    useEffect(() => {
        if (showSuccessAlert) {
            const timer = setTimeout(() => {
                setShowSuccessAlert(null);
            }, 5000);
            return () => clearTimeout(timer);
        }
        if (showFailedAlert) {
            const timer = setTimeout(() => {
                setShowFailedAlert(null);
            }, 60000);
            return () => clearTimeout(timer);
        }
    }, [showSuccessAlert, showFailedAlert]);

    useEffect(() => {
        if (!dataSource) {
            // Extract filters from URL search parameters
            const filtersFromParams = {};
            searchParams.forEach((value, key) => {
                filtersFromParams[key] = ['shipped_on', 'due_on', 'placed_on'].includes(key) ? [value.split(',')]: value.split(',');
            });
            setCurrentFilters(filtersFromParams);
            setSinceDate(filtersFromParams.since ? dayjs(filtersFromParams.since) : dayjs().subtract(6, 'months'));
            setOnlyOpen(filtersFromParams.onlyOpen !== undefined ? filtersFromParams.onlyOpen === 'true' : true);        

            fetchData().then(() => {
                if (dataSource) {
                    // Apply initial filters to the data
                    handleTableChange({}, filtersFromParams, {});
                }
            });        
        }
    }, []);

    useEffect(() => {
        fetchData().then(() => {
            handleTableChange({}, currentFilters, {});
        });
    }, [sinceDate, onlyOpen]);

    useEffect(() => {
        if (dataSource) {
            handleTableChange({}, currentFilters, {});
        }
    }, [dataSource]);

    useEffect(() => {
        if (dataSource && dataSource['tableData']) {
            const uniqueProductNames = getUniqueProductNames(dataSource['tableData']);
            const totalQuantities = calculateTotalQuantities(dataSource['tableData']);

            const productSortOrder = aggregateProductSortOrders(dataSource['tableData']);
            const productColumns = createProductColumns(uniqueProductNames, totalQuantities, productSortOrder);

            const customerPropsKeys = new Set();
            dataSource['tableData'].forEach(item => {
                Object.keys(item).forEach(key => {
                    if (key.startsWith('customer_prop_')) {
                        customerPropsKeys.add(key);
                    }
                });
            });

            // Generating columns for each unique customer property key
            const customerPropsColumns = Array.from(customerPropsKeys).map(key => ({
                title: key.replace('customer_prop_', '').replace(/_/g, ' ').charAt(0).toUpperCase() + key.replace('customer_prop_', '').slice(1), 
                dataIndex: key,
                key: key,
                ...getColumnSearchProps(key, key.replace('customer_prop_', '').replace(/_/g, ' ').charAt(0).toUpperCase() + key.replace('customer_prop_', '').slice(1)),
            }));

            const staticColumns = [
                {
                    title: 'Customer',
                    dataIndex: 'customer_name',
                    width: 200,
                    key: 'customer_name',
                    render: (val, row) => {
                        if (false) {
                            return (
                                <Link
                                    to={{
                                        pathname: '/sales/customer', 
                                        search: `?ids=${row['customer_ids'] ? row['customer_ids'].join(',') : row['customer_id']}` 
                                    }}
                                    style={{
                                        color: "#0091AE", // Set the text color to blue
                                        textDecoration: 'underline', // Underline the text
                                        cursor: 'pointer', // Change cursor to pointer when hovering
                                    }}
                                >
                                    {val}
                                </Link>
                            )
                        } else {
                            return val
                        }
                    },
                    sorter: (a, b) => {
                        return a.customer_name > b.customer_name ? 1 : -1
                    },
                    ...getColumnSearchProps('customer_name', 'Customer'),
                },
                {
                    title: 'PO #',
                    dataIndex: 'external_id',
                    width: 100,
                    key: 'external_id',
                    render: (val, row) => {
                        if (row['order_uuid']) {
                            return (
                                <Link
                                    to={{
                                        pathname: '/orders/order', 
                                        search: `?order_id=${row['order_uuid']}` 
                                    }}
                                    style={{
                                        color: "#0091AE", // Set the text color to blue
                                        textDecoration: 'underline', // Underline the text
                                        cursor: 'pointer', // Change cursor to pointer when hovering
                                    }}
                                >
                                    {val ? val : 'No Order #'}
                                </Link>
                            )
                        } else {
                            return val
                        }
                    },
                    ...getColumnSearchProps('external_id', 'PO #'),
                },
                {
                    title: '$ Total',
                    dataIndex: 'total_price',
                    width: 100,
                    key: 'total_price',
                    render: (val, row) => currencyFormatter.format(val),
                    sorter: (a, b) => a.total_price - b.total_price,
                },
                {
                    title: `Total ${unitDisplayLabels[orderUnit]}`,
                    dataIndex: 'total_units',
                    width: 100,
                    key: 'total_units',
                    render: (val, row) => detailedVolumeFormatter.format(val),
                    sorter: (a, b) => a.total_units - b.total_units,
                },
                {
                    title: 'Created At',
                    dataIndex: 'created_at',
                    width: 100,
                    key: 'created_at',
                    defaultSortOrder: 'created_at',
                    defaultSortOrder: 'descend',
                    render: (val, row) => val ? dayjs(val).format('MMM D YYYY') : '',
                    sorter: (a, b) => {
                        if (a.created_at === null) return -1;
                        if (b.created_at === null) return 1;
                        return dayjs(a.created_at).isAfter(dayjs(b.created_at)) ? 1 : -1;            
                    },
                    ...getDateRangeFilterProps('created_at'),
                },
                {
                    title: 'Placed On',
                    dataIndex: 'placed_on',
                    width: 100,
                    key: 'placed_on',
                    sorter: (a, b) => {
                        if (a.placed_on === null) return 1;
                        if (b.placed_on === null) return -1;
                        return dayjs(a.placed_on).isAfter(dayjs(b.placed_on)) ? 1 : -1;            
                    },
                    ...getDateRangeFilterProps('placed_on'),
                },
                {
                    title: 'Due On',
                    dataIndex: 'due_on',
                    width: 100,
                    key: 'due_on',
                    render: (val, row) => (val === null ? 'ASAP' : val),
                    sorter: (a, b) => {
                        if (a.due_on === null) return 1;
                        if (b.due_on === null) return -1;
                        return dayjs(a.due_on).isAfter(dayjs(b.due_on)) ? 1 : -1;        
                    },
                    ...getDateRangeFilterProps('due_on'),
                },
                {
                    title: 'Ship Date',
                    dataIndex: 'shipped_on',
                    width: 100,
                    key: 'shipped_on',
                    sorter: (a, b) => {
                        if (a.shipped_on === null) return 1;
                        if (b.shipped_on === null) return -1;
                        return dayjs(a.shipped_on).isAfter(dayjs(b.shipped_on)) ? 1 : -1;                
                    },
                    ...getDateRangeFilterProps('shipped_on'),
                },
                {
                    title: 'Status',
                    dataIndex: 'status',
                    width: 200,
                    key: 'status',
                    filters: dataSource['tableData'].map((r) => ({
                        text: r.status,
                        value: r.status
                    })).filter((value, index, self) => 
                        index === self.findIndex((t) => (
                            t.text === value.text && t.value === value.value
                        ))
                    ),
                    onFilter: (value, record) => record.status === value,
                },
                {
                    title: (
                        <Tooltip title="Incoterms are standard rules that describe method of goods transport">
                            Incoterm <InfoCircleOutlined />
                        </Tooltip>
                    ),
                    dataIndex: 'fob',
                    width: 200,
                    key: 'fob',
                    render: (val) => (
                        fobDisplayLabels[val]
                    ),
                    filters: dataSource['tableData'].map((r) => ({
                        text: fobDisplayLabels[r.fob],
                        value: r.fob
                    })).filter((value, index, self) => 
                        index === self.findIndex((t) => (
                            t.text === value.text && t.value === value.value
                        ))
                    ),
                    onFilter: (value, record) => record.fob === value,
                },
                // {
                //     title: 'Warehouse',
                //     dataIndex: 'warehouse',
                //     width: 200,
                //     key: 'warehouse',
                //     sorter: (a, b) => {
                //         return a.warehouse > b.warehouse ? 1 : -1
                //     },
                //     filters: dataSource['tableData'].map((r) => ({
                //         text: r.warehouse,
                //         value: r.warehouse
                //     })).filter((value, index, self) => 
                //         index === self.findIndex((t) => (
                //             t.text === value.text && t.value === value.value
                //         ))
                //     ),
                //     onFilter: (value, record) => record.warehouse === value,
                // },
                {
                    title: 'Notes',
                    dataIndex: 'notes',
                    key: 'notes',
                    ellipsis: {
                        showTitle: false,
                    },
                    render: (notes) => (
                        <Tooltip placement="topLeft" title={notes}>
                            {notes}
                        </Tooltip>
                    ),
                    onCell: () => ({
                        style: {
                            maxWidth: 300, // Set the maximum width for the column
                            whiteSpace: 'nowrap',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                        },
                    }),
                },
            ];
            
            setColumns([...customerPropsColumns, ...staticColumns, ...productColumns]);
            setAllProductColumns([...customerPropsColumns, ...staticColumns, ...productColumns]);

            if (!statusData) {
                const newStatusData = aggregateDataByStatus(dataSource['tableData']);    
                setStatusData(newStatusData);
            }
        }
    }, [dataSource, orderUnit]);

    const getDateRangeFilterProps = (dataIndex) => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
            <div style={{ padding: 8 }}>
                <RangePicker
                    onChange={(dates, dateStrings) => {
                        setSelectedKeys(dateStrings ? [dateStrings] : []);
                    }}
                    style={{ marginBottom: 8, display: 'block' }}
                />
                <Space>
                    <Button
                        type="primary"
                        onClick={() => confirm()}
                        icon={<FilterOutlined />}
                        size="small"
                        style={{ width: 90 }}
                    >
                        Filter
                    </Button>
                    <Button
                        onClick={() => {
                            clearFilters();
                            confirm();
                        }}
                        size="small"
                        style={{ width: 90 }}
                    >
                        Clear
                    </Button>
                </Space>
            </div>
        ),
        onFilter: (value, record) => {
            const [start, end] = value;
            const recordDate = dayjs(record[dataIndex]).format('YYYY-MM-DD');
            return recordDate >= start && recordDate <= end;
        },
    });

    const getColumnSearchProps = (dataIndex, columnName) => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
          <div style={{ padding: 8 }} onKeyDown={(e) => e.stopPropagation()}>
            <Input
              ref={searchInput}
              placeholder={`Filter ${columnName}`}
              value={selectedKeys[0]}
              onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
              onPressEnter={() => confirm()}
              style={{ marginBottom: 8, display: 'block' }}
            />
            <Space>
              <Button
                type="primary"
                onClick={() => confirm()}
                icon={<FilterOutlined />}
                size="small"
                style={{ width: 90 }}
              >
                Filter
              </Button>
              <Button
                onClick={() => {clearFilters(); confirm();}}
                size="small"
                style={{ width: 90 }}
              >
                Clear
              </Button>
            </Space>
          </div>
        ),
        onFilter: (value, record) => {
            return record[dataIndex] ? record[dataIndex]
            .toString()
            .toLowerCase()
            .includes((value).toLowerCase()) : null
        },
        onFilterDropdownOpenChange: (visible) => {
          if (visible) {
            setTimeout(() => searchInput.current?.select(), 100);
          } 
        },
      });

    // Function to extract unique product names
    const getUniqueProductNames = (data) => {
        const productNames = new Set();
        data.forEach(item => {
            item.product_names.forEach(name => productNames.add(name));
        });
        return Array.from(productNames);
    };

    // Function to calculate total quantities for each product
    const calculateTotalQuantities = (data) => {
        const totals = new Map();
        data.forEach(item => {
            item.product_names.forEach((name, index) => {
                totals.set(name, (totals.get(name) || 0) + item.product_quantities[index]);
            });
        });
        return totals;
    };

    const aggregateProductSortOrders = (data) => {
        const sortOrderMap = new Map();
    
        data.forEach(row => {
            row.product_names.forEach((productName, index) => {
                const sortOrder = row.product_sort_orders[index];
                // Only update the sort order if it's not already set or if the new index is lower
                if (!sortOrderMap.has(productName) || sortOrderMap.get(productName) > sortOrder) {
                    sortOrderMap.set(productName, sortOrder);
                }
            });
        });
    
        // Convert the map to a sorted array based on the index
        return Array.from(sortOrderMap).sort((a, b) => b[1] - a[1]).map(entry => entry[0]);
    };

    // Function to create columns for each product
    const createProductColumns = (uniqueProductNames, totalQuantities, productSortOrder) => {
        const sortOrderMap = new Map(productSortOrder.map((name, index) => [name, index]));
        
        return uniqueProductNames
            .sort((a, b) => {
                // Use the sort order map to determine the order
                const aOrder = sortOrderMap.has(a) ? sortOrderMap.get(a) : Infinity;
                const bOrder = sortOrderMap.has(b) ? sortOrderMap.get(b) : Infinity;
                return aOrder - bOrder;
            })
            .map(name => ({
                title: (
                    <Tooltip title={name}>
                        {truncateText(name, 20)}
                    </Tooltip>
                ),
                dataIndex: 'product_names',
                key: name,
                width: 50,
                render: (productNames, record) => {
                    const index = productNames.indexOf(name);
                    return index > -1 ? detailedVolumeFormatter.format(record.product_quantities[index]) : '0';
                },
            }));
    };
    
    const getUniqueProducts = (data) => {
        const productSet = new Set();
        data.forEach(item => {
            item.product_names.forEach(product => {
                productSet.add(product);
            });
        });
        return Array.from(productSet);
    };

    const prepareDataForExport = (data, exportType) => {
        // Define the subset of column keys that should be ordered first in the export
        const exportColumnOrder = ['customer_id', 'product_id', 'quantity', 'external_id', 'notes'];
    
        // Helper function to reorder data according to exportColumnOrder
        const reorderData = (data) => {
            return data.map(order => {
                let reorderedData = {};
    
                // Add specified columns in order
                exportColumnOrder.forEach(column => {
                    reorderedData[column] = order[column];
                });
    
                // Add the rest of the data not specified in exportColumnOrder
                Object.keys(order).forEach(key => {
                    if (!exportColumnOrder.includes(key)) {
                        reorderedData[key] = order[key];
                    }
                });
    
                return reorderedData;
            });
        };
    
        if (exportType === 'OneLinePerOrder') {
            const uniqueProducts = getUniqueProducts(data);
    
            // Create the data with products dynamically added
            const dataWithProducts = data.map(order => {
                const productQuantities = {};
                uniqueProducts.forEach(product => {
                    const index = order.product_names.indexOf(product);
                    productQuantities[product] = index > -1 ? order.product_quantities[index] : 0;
                });
                return { ...order, ...productQuantities };
            });
    
            // Reorder the data according to exportColumnOrder, maintaining additional data integrity
            return reorderData(dataWithProducts);
    
        } else if (exportType === 'OneLinePerItem') {
            let results = [];
            data.forEach(order => {
                order.product_names.forEach((productName, index) => {
                    const productDetails = {
                        ...order,
                        product_name: productName,
                        product_id: index > -1 ? order.product_uuids[index] : 0,
                        quantity: index > -1 ? order.product_quantities[index] : 0,
                    };
                    results.push(productDetails);
                });
            });
            // Reorder each line item entry similarly
            return reorderData(results);
        }
    };

    const handleMenuClick = (e) => {
        const selectedAndFiltered = filteredTableData.filter((r) => selectedRows.includes(r.order_uuid))
        if (e.key === '1') {
          exportToExcel(prepareDataForExport(selectedAndFiltered, 'OneLinePerOrder'), 'Orders_Export');
        } else if (e.key === '2') {
          exportToExcel(prepareDataForExport(selectedAndFiltered, 'OneLinePerItem'), 'Itemized_Orders_Export');
        }
    };

    const handleActionsClick = (e) => {
        if (e.key === '1') {
          setBulkActionModal(true);
        } else if (e.key === '2') {
        //   exportToExcel(prepareDataForExport(filteredTableData, 'OneLinePerItem'), 'Itemized_Orders_Export');
        }
    };

    const rowSelection = {
        onChange: (selectedRowKeys, selectedRows) => {
            setSelectedRows(selectedRowKeys);
        },
    };

    const truncateText = (text, maxLength) => {
        if (!text) return text;

        if (text.length <= maxLength) return text;
        return `${text.substring(0, maxLength)}...`;
    };

    const aggregateDataByStatus = (data) => {
        const statusAggregates = new Map();

        data.forEach(item => {
            const { status, total_price } = item;
            if (!statusAggregates.has(status)) {
                statusAggregates.set(status, { count: 0, totalPrice: 0 });
            }
            const aggregate = statusAggregates.get(status);
            aggregate.count++;
            aggregate.totalPrice += total_price;
        });

        return Array.from(statusAggregates, ([status, { count, totalPrice }]) => ({
            status, count, totalPrice
        }));
    };

    const handleTableChange = (pagination, filters, sorter) => {
        if (!dataSource) return; 

        // Start with the full dataset
        let filteredData = [...dataSource['tableData']];

        // Merge current filters with new filters, ignoring null or undefined values
        const activeFilters = { ...currentFilters };
        Object.entries(filters).forEach(([key, value]) => {
            if (value === null || value === undefined || value.length === 0) {
                delete activeFilters[key];
            } else {
                activeFilters[key] = value;
            }
        });

        // Apply each filter. Iterate over the filters object.
        Object.keys(activeFilters).forEach(key => {
            const filterValues = filters[key];
            if (filterValues && filterValues.length > 0) {
                if (key === 'customer_name' || key === 'external_id' || key.startsWith('customer_prop_')) {
                    filteredData = filteredData.filter(row => row[key] && row[key].toLowerCase().includes(filterValues[0].toLowerCase()))
                } else if (key === 'placed_on' || key === 'shipped_on' || key === 'due_on') {
                    const [start, end] = filterValues[0];

                    filteredData = filteredData.filter(row => (dayjs(row[key]) >= dayjs(start) && dayjs(row[key]) <= dayjs(end)));
                } else {
                    filteredData = filteredData.filter(row => row[key] && filterValues.includes(row[key])) 
                }
            }
        });

        setFilteredData(filteredData);
        setCurrentFilters(activeFilters);

        // Update URL search parameters
        const newSearchParams = new URLSearchParams();
        Object.entries(activeFilters).forEach(([key, value]) => {
            if (Array.isArray(value) && value.length > 0) {
                if (key === 'placed_on' || key === 'shipped_on' || key == 'due_on') {                    
                    // Handle date range filters
                    newSearchParams.set(key, value);
                } else {
                    // Handle array filters
                    newSearchParams.set(key, value.join(','));
                }
            } else if (value) {
                // Handle non-array filters
                newSearchParams.set(key, value);
            }
        });
        setSearchParams(newSearchParams);
    };
    

    if (!dataSource) return <Loading style={{paddingTop: 200}}/>

    return (
        <div style={{padding: '20px 20px 20px 20px', overflow: 'auto', height: 'calc(100vh - 58px)'}}>
            <Row gutter={16} style={{ marginBottom: 16 }}>
                {statusData.map(({ status, count, totalPrice }) => (
                    <Col flex = 'auto' key={status}>
                        <Card title={undefined}>
                            <Statistic
                                title={status}
                                value={volumeFormatter.format(count)}
                                precision={0} 
                                suffix={'Orders'}
                            />               
                            <Statistic
                                value={currencyFormatter.format(totalPrice)}
                                precision={0} 
                            />                      
                        </Card>
                    </Col>
                ))}
            </Row>
            <Row style={{ marginBottom: 16 }}>
                <Col flex={'300px'}>
                    {dataSource['tableData'].length > 0 && dataSource['tableData'][0].order_uuid && 
                        <OrderSearch userData={userData} navLinks={true}/>
                    }
                </Col>
                <Col flex={'auto'}></Col>
                <Col flex={'175px'}>
                    <Button
                        icon={<SlidersOutlined />}
                        iconPosition={'end'}
                        onClick={() => setDrawerVisible(!drawerVisible)}                        
                    >
                        Advanced Filters
                    </Button>
                </Col>
                <Col flex={'180px'}>
                    <Dropdown overlay={<Menu onClick={handleActionsClick}>
                        <Menu.Item key="1">Update Status</Menu.Item>
                        {/* <Menu.Item key="2">Export Selection</Menu.Item> */}
                        </Menu>} trigger={['click']}>
                        <Button
                            iconPosition={'end'}
                            icon={<CaretDownOutlined/>}
                        >
                            Bulk Actions ({selectedRows.length})
                        </Button>
                    </Dropdown>
                </Col>
                <Col flex={'100px'}>
                    <Dropdown overlay={<Menu onClick={handleMenuClick}>
                        <Menu.Item key="1">One line per order</Menu.Item>
                        <Menu.Item key="2">One line per order line item</Menu.Item>
                        </Menu>} trigger={['click']}>
                        <Button 
                            icon={<DownloadOutlined/>}
                            iconPosition={'end'}
                            type="primary"
                        >
                            Export Orders {selectedRows.length > 0 ? `(${selectedRows.length})` : ''}
                        </Button>
                    </Dropdown>
                </Col>
            </Row>
            {loading ? <Skeleton active paragraph={{ rows: 10 }} /> : filteredTableData && columns.length > 0 && <Table
                rowSelection={rowSelection}
                style={{ 
                    overflowX: 'auto', 
                    scrollbarWidth: 'thin',
                    '::-webkit-scrollbar': {
                        height: '10px' 
                    }
                }}
                pagination={{
                    defaultPageSize: 25,
                    showSizeChanger: true,
                    pageSizeOptions: ['25', '50', '100'],
                }}
                bordered
                dataSource={filteredTableData}
                columns={columns}
                scroll={{ x: 'max-content' }} // Enable horizontal scrolling
                onChange={handleTableChange}
            />}
            <Drawer
                title="Advanced Filters"
                placement="right"
                width={250}
                onClose={() => setDrawerVisible(false)}
                open={drawerVisible}
            >
                <Row gutter={16} style={{marginBottom: 30}}>
                    <Switch
                        checked={onlyOpen}
                        onChange={setOnlyOpen}
                        checkedChildren="Open Orders"
                        unCheckedChildren="All Orders"
                    />
                </Row>
                <Row gutter={16}>
                    <b>Orders Created After</b>
                    <DatePicker
                        onChange={(date) => setSinceDate(date)}
                        defaultValue={sinceDate}
                    />
                </Row>
            </Drawer>
            <Modal
                title="Bulk Update"
                open={bulkActionModal}
                width={700}
                onOk={() => saveBulk()}
                onCancel={() => setBulkActionModal(false)}
            >
                <Row>
                    <Typography.Text>Set the Status of selected orders ({selectedRows.length}) to:</Typography.Text>
                </Row>
                <Row>
                    <Select
                        value={bulkStatus}
                        defaultValue={'Invoiced'}
                        style={{minWidth: 350}}
                        onChange={value => setBulkStatus(value)}
                    >
                        {statusOptions.map(option => (
                            <Select.Option key={option.value} value={option.value}>
                                {option.closed ? <Tag color='yellow'>Closed</Tag> : <Tag color='blue'>Open</Tag>} {option.label}
                            </Select.Option>
                        ))}
                    </Select>
                </Row>
            </Modal>
            {showSuccessAlert !== null && (
                <Alert
                    message={"Orders Saved"}
                    type="success"
                    showIcon
                    style={{
                        position: 'absolute',
                        bottom: 10,
                        left: '50%',
                        transform: 'translateX(-50%)',
                        zIndex: 1000,
                        width: '25%'
                    }}
                />
            )}
            {showFailedAlert !== null && (
                <Alert
                    message={showFailedAlert}
                    type="error"
                    showIcon
                    closable
                    style={{
                        position: 'absolute',
                        bottom: 10,
                        left: '50%',
                        transform: 'translateX(-50%)',
                        zIndex: 1000,
                        width: '35%'
                    }}
                />
            )}
        </div>
    )
}

export default OpenOrderExplorer;